import $ from 'jquery';
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';

init();

function init() {
  initLightbox();
  initLoaders();
  ga();
}
function initLoaders() {
  var img = $('.project_card_img');
  img.hide();
  img.on('load', function () {
    console.log('img loaded');
    img.fadeIn();
  })
}

function displayProjects(data) {
  data.results.forEach(function (item) {
    var newProject = new project(item.data.title[0].text, item.data.image.url, item.data.description[0].text);
    var theNewProject = "<div class='project_card'><div class='project_card_img'><a href=" + newProject.image + "><i class='loader fas fa-2x fa-circle-notch fa-spin'></i><img src=" + newProject.image + " /></a></div><div><h2>" + newProject.title + "</h2><p>" + newProject.description + "</p></div></div>";
    $('#projects').append(theNewProject);
    $('.project_card_img img').on('load', function () {
      console.log('img loaded');
      $(this).prev('.loader').remove();
      $(this).css({
        'opacity': 1
      })
    })
  })

}
function displayProjectsHome(data) {
  var i = 0;
  data.results.forEach(function (item) {
    if (i <= 3) {
      var newProject = new project(item.data.title[0].text, item.data.image.url, item.data.description[0].text);
      var theNewProject = "<div class='project_card'><div class='project_card_img'><a href=" + newProject.image + "><i class='loader fas fa-2x fa-circle-notch fa-spin'></i><img src=" + newProject.image + " /></a></div><div><h2>" + newProject.title + "</h2><p>" + newProject.description + "</p></div></div>";
      $('#projectsHome').append(theNewProject);
      $('.project_card_img img').on('load', function () {
        console.log('img loaded');
        $(this).prev('.loader').remove();
        $(this).css({
          'opacity': 1
        })
      });
      i++;
    }
  });
}
function initLightbox() {
  const lightbox = new PhotoSwipeLightbox({
    gallery: '.gallery',
    children: 'a',
    bgOpacity: 0.7,
    thumbSelector: 'a',
    pswpModule: () => import('photoswipe/dist/photoswipe.esm')
  });

  lightbox.addFilter('domItemData', (itemData, element, linkEl) => {
    if (linkEl) {
      const sizeAttr = linkEl.dataset.mySize;
  
      itemData.src = linkEl.href;
      itemData.w = Number(sizeAttr.split('x')[0]);
      itemData.h = Number(sizeAttr.split('x')[1]);
      itemData.msrc = linkEl.dataset.thumbSrc;
      itemData.thumbCropped = true;
    }
  
    return itemData;
  });
  lightbox.init();

}

function UiInit() {
  $('#home_banner').carousel({
    transition: 'fade',
    autoplay: 'pause',
    cycle: true,
    cover   : true,
    height  : '10rem',
    lazyLoad: 'nearby',
    breakpoints: {
      height: '6rem',
    },
  });

  menuAction();
  // particlesJS.load('spyder', 'assets/particles.json');
  // var year = new Date().getFullYear();
  // $('.year').text(year);
  // particlesJS.load('pageHeader', 'assets/particles.json');
  // particlesJS.load('darkSpyder', 'assets/particlesDark.json');
}

function hideElements() {
  $('.menuBox').hide();
}

function menuAction() {
  $('.menuBoxOpen').click(function () {
    $('.menuBox').css({
      "display": "block",
      "animation": "slideInLeft 0.5s"
    });
    $(".menuBox a").each(function (i) {
      var el = $(this);
      setTimeout(function () {
        el.css({
          "visibility": "visible",
          "animation": "flipInY 1s"
        }, 200);
      }, i * 250);
    });
    // $('.menuBox').slideDown();
  });
  $('.menuClose').click(function () {
    $('.menuBox').css({
      "animation": "slideOutLeft 0.5s"
    });
    setTimeout(function () {
      $('.menuBox').css({
        "display": "none"
      });
      $('.menuBox a').css({
        "visibility": "hidden",
        "animation": "none"
      });
    }, 350);
    // $('.menuBox').slideUp();
  })
}

function project(title, image, desc) {
  this.title = title;
  this.image = image;
  this.description = desc;
}

function ga(){
  var googletagmanager = document.createElement("script");
  googletagmanager.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-JXJMKQHZXK");
  document.getElementsByTagName('head')[0].appendChild(googletagmanager);

  googletagmanager.onload = function () {
     window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-JXJMKQHZXK');
  };
}
